import axios from "@/axios";

const state = {
  institutes: {
    data: [],
  },
};

const getters = {
  allInstitutes: (state) => state.institutes.data,
  getInstitute: (state) => (id) => {
    return state.institutes.data.find((institutes) => institutes.id === id);
  },
};
const actions = {
  async fetchInstitutes({ commit }) {
    const response = await axios.get("/public/institutes");
    commit("SET_INSTITUTE_DATA", response.data);
  },
};

const mutations = {
  SET_INSTITUTE_DATA: (state, data) => {
    state.institutes.data = data.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
