<template>
  <div class="page-container py-7" v-if="allArticles && allArticles.length > 0">
    <v-container fluid>
      <v-row align="stretch">
        <!-- First Article -->
        <v-col cols="12" md="6" class="pa-1">
          <v-card
            @click="postDetails(allArticles[0])"
            elevation="0"
            class="blog-card h-100"
            :style="{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${
                allArticles[0].images && allArticles[0].images.length > 0
                  ? allArticles[0].images[0].thumbnail_path
                  : require('@/assets/No-Image-Placeholder.png')
              }')`,
            }"
          >
            <!-- Card Content -->
            <v-card-title class="px-2" v-if="allArticles[0].images.length > 0">
              <span
                v-if="allArticles[0].images[0].type == 'original'"
                class="approved-tag white--text px-4 fa-12 d-block"
              >
                <v-icon small dark class="white--text"
                  >mdi-check-circle-outline</v-icon
                >
                {{ json.postStatus.approved }}</span
              >
              <span v-else class="fake-tag white--text px-5 fa-12 d-block">
                <v-icon small dark class="white--text"
                  >mdi-hand-back-left</v-icon
                >
                {{ json.postStatus.fake }}</span
              >
            </v-card-title>
            <v-card-title class="pb-0">
              <span
                v-if="language == 'en' && allArticles[0].title_english != null"
                class="white--text text-truncate fa-18 mb-2"
                >{{ allArticles[0].title_english }}</span
              >
              <span v-else class="white--text text-truncate fa-18 mb-2">{{
                allArticles[0].title_arabic
              }}</span>
            </v-card-title>
            <v-card-title class="py-0 mb-3">
              <span
                v-if="
                  language == 'en' && allArticles[0].content_english != null
                "
                class="white--text text-truncate-two-lines fa-12"
                >{{ allArticles[0].content_english }}</span
              >
              <span v-else class="white--text text-truncate-two-lines fa-12">{{
                allArticles[0].content_arabic
              }}</span>
            </v-card-title>
            <span
              v-if="language == 'en'"
              class="white-panel-1 px-5 mx-2 fa-14 py-1"
              >{{ allArticles[0].category_name_english }}</span
            >
            <span v-else class="white-panel-1 px-5 mx-2 fa-14 py-1">{{
              allArticles[0].category_name_arabic
            }}</span>
            <v-row class="mt-6 pb-3">
              <v-col class="align-self-end fa-10 py-0" cols="12">
                <v-icon class="white--text mx-2"
                  >mdi-calendar-account-outline</v-icon
                >
                <span class="white--text">{{
                  formatDate(allArticles[0].created_at)
                }}</span>
              </v-col>
              <v-col class="align-self-end fa-12 py-0" cols="12">
                <v-icon class="white--text mx-2">mdi-eye-outline</v-icon>
                <span class="white--text">{{
                  allArticles[0].views_count
                }}</span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" class="">
          <v-row align-content="stretch">
            <v-col
              v-for="(item, index) in allArticles.slice(1, 7)"
              :key="index"
              cols="12"
              md="6"
              class="mb-0 pa-1"
            >
              <v-card
                @click="postDetails(item)"
                elevation="0"
                class="blog-card"
                :style="{
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${
                    item.images && item.images.length > 0
                      ? item.images[0].thumbnail_path
                      : require('@/assets/No-Image-Placeholder.png')
                  }')`,
                }"
              >
                <v-card-title
                  v-if="item.images.length > 0"
                  class="pb-0 pt-2 px-2"
                >
                  <span
                    v-if="item.images[0].type == 'original'"
                    class="approved-tag white--text px-4 fa-12 d-block"
                  >
                    <v-icon small dark class="white--text"
                      >mdi-check-circle-outline</v-icon
                    >
                    {{ json.postStatus.approved }}</span
                  >
                  <span v-else class="fake-tag white--text px-5 fa-12 d-block">
                    <v-icon small dark class="white--text"
                      >mdi-hand-back-left</v-icon
                    >
                    {{ json.postStatus.fake }}</span
                  >
                </v-card-title>
                <v-card-title class="pb-0">
                  <span
                    v-if="language == 'en' && item.title_english != null"
                    class="white--text text-truncate fa-18 mb-2"
                    >{{ item.title_english }}</span
                  >
                  <span v-else class="white--text text-truncate fa-18 mb-2">{{
                    item.title_arabic
                  }}</span>
                </v-card-title>
                <v-card-title class="py-0 mb-3">
                  <span
                    v-if="language == 'en' && item.content_english != null"
                    class="white--text text-truncate-two-lines fa-12"
                    >{{ item.content_english }}</span
                  >

                  <span
                    v-else
                    class="white--text text-truncate-two-lines fa-12"
                    >{{ item.content_arabic }}</span
                  >
                </v-card-title>

                <span
                  v-if="language == 'en'"
                  class="white-panel-1 px-5 mx-2 fa-14 py-1"
                  >{{ item.category_name_english }}</span
                >
                <span v-else class="white-panel-1 px-5 mx-2 fa-14 py-1">{{
                  item.category_name_arabic
                }}</span>

                <div v-if="index == 0" style="height: 30px"></div>
                <div v-else style="height: 30px"></div>
                <v-row class="mt-auto pb-3">
                  <v-col class="align-self-end fa-10 py-0" cols="12">
                    <v-icon class="white--text mx-2"
                      >mdi-calendar-account-outline</v-icon
                    >
                    <span class="white--text">{{
                      formatDate(item.created_at)
                    }}</span>
                  </v-col>
                  <v-col class="align-self-end fa-12 py-0" cols="12">
                    <v-icon class="white--text mx-2">mdi-eye-outline</v-icon>
                    <span class="white--text">{{ item.views_count }}</span>
                  </v-col>
                </v-row>
                <div v-if="item.images.length > 0">
                  <div
                    v-if="item.images[0].type == 'original'"
                    class="approved-div mt-2"
                  ></div>
                  <div v-else class="fake-div mt-2"></div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    const lang = localStorage.getItem("lang") || "ar";
    return {
      info: {
        page: 1,
        keyword: null,
      },
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      direction: lang == "ar" ? "right" : "left",
      opposite_direction: lang == "ar" ? "left" : "right",
      language: lang,
    };
  },
  computed: {
    ...mapGetters(["allArticles", "getRole", "getUser"]),

    currentGet: {
      get() {
        return this.$store.getters.getArticleCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setArticleCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getArticleLastPage;
      },
    },
  },

  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.info.page = newVal;
        this.info.keyword = this.search;
        this.disablePagination = true;
        this.fetchArticles(this.info)
          .then(() => {
            this.disablePagination = false;
          })
          .catch(() => {
            this.disablePagination = false;
          });
      }
    },
  },

  created() {
    this.disablePagination = true;
    this.fetchArticles(this.info)
      .then(() => {
        this.disablePagination = false;
      })
      .catch(() => {
        this.disablePagination = false;
      });
  },

  methods: {
    ...mapActions(["fetchArticles"]),

    showArticleDetails(item) {
      this.articleData = item;
      this.EditArticleDialog = true;
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    postDetails(item) {
      this.$router.push({ name: "PostDetails", params: { id: item.id } });
    },
  },
};
</script>
<style scoped></style>
