<template>
  <v-container fluid class="py-10">
    <v-row align="center">
      <v-col cols="12" md="auto" class="d-flex align-center">
        <img
          src="../assets/PlatformLogo-01.svg"
          class="my-auto"
          width="100"
          height="auto"
        />
        <span class="fa-20 ml-2">{{ json.teams.title }}</span>
      </v-col>
    </v-row>

    <v-row align-content="stretch">
      <!-- Platform Management Team Section -->
      <v-col cols="12">
        <v-card elevation="0" class="mx-auto py-5">
          <v-col cols="10" class="py-0">
            <v-card-text class="fa-22">
              {{ json.teams.platform_management_team.title }}
            </v-card-text>
            <p class="fa-14">
              {{ json.teams.platform_management_team.formation }}
            </p>
          </v-col>
          <v-divider class="py-3"></v-divider>
          <ul class="fa-14 px-3">
            <li
              v-for="(responsibility, index) in json.teams
                .platform_management_team.responsibilities"
              :key="index"
            >
              <v-icon left>mdi-check-circle-outline</v-icon>
              <span class="mx-2">{{ responsibility }}</span>
            </li>
          </ul>
        </v-card>
      </v-col>

      <!-- Platform Standards Section -->
      <v-col cols="12">
        <v-card elevation="0" class="mx-auto py-4">
          <v-row align="center" class="px-3">
            <v-col cols="10">
              <v-card-text class="fa-22">
                {{ json.teams.monitoring_team.title || "" }}
              </v-card-text>
              <p class="fa-14">
                {{ json.teams.monitoring_team.formation || "" }}
              </p>
            </v-col>
          </v-row>
          <v-divider class="py-3"></v-divider>
          <ul class="fa-14 px-3">
            <li
              v-for="(responsibility, index) in json.teams.monitoring_team
                .responsibilities"
              :key="index"
            >
              <v-icon left color="success">mdi-check</v-icon>
              {{ responsibility }}
            </li>
          </ul>
        </v-card>
      </v-col>

      <!-- Platform Standards Section -->
      <v-col cols="12">
        <v-card elevation="0" class="mx-auto py-3">
          <v-row align="center" class="px-3">
            <v-col cols="10">
              <v-card-text class="fa-22">
                {{ json.teams.communication_team.title || "" }}
              </v-card-text>
              <p class="fa-14">
                {{ json.teams.communication_team.formation || "" }}
              </p>
            </v-col>
          </v-row>
          <v-divider class="py-3"></v-divider>
          <ul class="fa-14 px-3">
            <li
              v-for="(standard, index) in json.teams.communication_team
                .responsibilities"
              :key="index"
            >
              <v-icon left color="success" class="mx-2">mdi-check</v-icon>
              {{ standard }}
            </li>
          </ul>
        </v-card>
      </v-col>

      <!-- Supporting Entities Section -->
      <v-col cols="12">
        <v-card elevation="0" class="mx-auto py-3">
          <v-col cols="10">
            <v-card-text class="fa-22">
              {{ json.teams.editing_and_publishing_team.title || "" }}
            </v-card-text>
            <p class="fa-14">
              {{ json.teams.editing_and_publishing_team.formation || "" }}
            </p>
          </v-col>
          <v-divider class="py-3"></v-divider>
          <ul class="fa-14 px-3">
            <li
              v-for="(entity, index) in json.teams.editing_and_publishing_team
                .responsibilities"
              :key="index"
            >
              <v-icon left color="info" class="mx-2">mdi-domain</v-icon>
              {{ entity }}
            </li>
          </ul>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: function () {
    const lang = localStorage.getItem("lang") || "ar";

    return {
      json: lang === "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
    };
  },
};
</script>

<style scoped>
.page-container {
  background-color: #f9f9f9;
}

.v-card {
  border-radius: 8px;
}

.v-icon {
  vertical-align: middle;
}

.fa-20 {
  font-size: 20px;
}

.fa-22 {
  font-size: 22px;
}

.fa-14 {
  font-size: 14px;
}

.v-card-text {
  margin-bottom: 0;
}
</style>
