<template>
  <div>
    <div class="dark-banner">
      <div class="page-container">
        <div v-show="$vuetify.breakpoint.mdAndDown">
          <v-row class="my-auto dark-banner white--text">
            <v-col cols="2" class="my-auto">
              <v-app-bar-nav-icon
                dark
                @click="drawer = true"
                class="d-flex"
              ></v-app-bar-nav-icon>
            </v-col>

            <v-col cols="2" class="px-0 my-auto py-0">
              <img
                src="../assets/PlatformLogo-04.svg"
                class=""
                width="100%"
                height="auto"
              />
            </v-col>
            <v-col cols="8" class="my-auto">
              <h4 class="normal-font">
                <span class="ink-tab small-text">
                  {{ $t("title") }} | {{ $t("subtitle") }}</span
                >
              </h4>
            </v-col>
          </v-row>
        </div>

        <div v-show="$vuetify.breakpoint.lgAndUp" class="pt-2">
          <div class="d-flex dark-banner white--text py-2">
            <v-col cols="1" class="px-0 my-auto py-0">
              <img
                src="../assets/PlatformLogo-04.svg"
                class=""
                width="100%"
                height="auto"
              />
            </v-col>
            <v-col cols="8" class="my-auto">
              <span>
                <span class="ink-tab small-text fa-16">
                  {{ $t("title") }} | {{ $t("subtitle") }}</span
                >
              </span>
            </v-col>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn class="my-auto" dark icon @click="showSearchModal = true">
              <v-icon dark>mdi-magnify</v-icon>
            </v-btn>

            <!-- Language Selection Dropdown -->
            <v-menu offset-y class="mx-2">
              <template v-slot:activator="{ on }">
                <div class="d-flex my-auto mx-4 fa-16 off-white px-3">
                  <v-btn v-on="on">
                    <v-icon light> mdi-chevron-down </v-icon>
                    <span class="">{{
                      selectedLanguage === "ar" ? "EN" : "AR"
                    }}</span>
                  </v-btn>
                  <!-- Display language label outside the button -->
                </div>
              </template>

              <v-list>
                <v-list-item @click="switchLanguage('ar')">العربية</v-list-item>
                <v-list-item @click="switchLanguage('en')">English</v-list-item>
              </v-list>
            </v-menu>
            <div class="my-auto">
              <!-- Language Selection Dropdown -->

              <v-btn
                v-for="(socialButton, index) in socialButtons"
                :key="index"
                icon
                :href="socialButton.href"
                target="_blank"
              >
                <v-icon dark class="off-white">{{ socialButton.icon }}</v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <v-navigation-drawer v-model="drawer" absolute temporary>
          <v-list nav dense>
            <v-menu offset-y class="mx-2">
              <template v-slot:activator="{ on }">
                <div class="d-flex my-4 mx-4 fa-16 off-white px-3">
                  <v-btn v-on="on" elevation="0">
                    <v-icon light> mdi-chevron-down </v-icon>
                    <span class="">{{
                      selectedLanguage === "ar" ? "EN" : "AR"
                    }}</span>
                  </v-btn>
                  <!-- Display language label outside the button -->
                </div>
              </template>

              <v-list>
                <v-list-item @click="switchLanguage('ar')">العربية</v-list-item>
                <v-list-item @click="switchLanguage('en')">English</v-list-item>
              </v-list>
            </v-menu>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item v-for="(item, i) in menus_2" :key="i" :to="item.url">
                <v-icon small light>{{ item.icon }}</v-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="px-2">{{ item.title }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
    </div>

    <!--Sub-list -->
    <div class="py-sm-0 py-md-3 py-lg-3 mx-auto sub-list-menu white">
      <div class="page-container">
        <v-row v-show="$vuetify.breakpoint.mdAndUp" class="white--text mx-auto">
          <v-col
            v-for="(menu, index) in menus"
            :key="index"
            cols="12"
            md="auto"
          >
            <div v-if="menu.children">
              <v-menu offset-y v-model="menu.isActive">
                <template v-slot:activator="{ on }">
                  <span
                    class="black--text undecorated"
                    v-on="on"
                    @click="makeListAppear(menu)"
                  >
                    <v-icon>{{ menu.icon }}</v-icon>
                    {{ menu.title }}
                    <v-icon v-if="menu.children">mdi-chevron-down</v-icon>
                  </span>
                </template>
                <v-list
                  v-if="menu.children && menu.showChildren"
                  elevation="0"
                  class="v-list-box-shadow-none"
                  :dir="$direction"
                >
                  <v-list-item
                    v-for="(item, i) in menu.children"
                    :key="i"
                    :to="item.url"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="fa-14">
                        <v-icon small class="mx-2">{{ item.icon }}</v-icon>
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <div v-else>
              <a :href="menu.url" class="black--text undecorated" v-on="on">
                <v-icon>{{ menu.icon }}</v-icon>
                {{ menu.title }}
                <v-icon v-if="menu.children">mdi-chevron-down</v-icon>
              </a>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <search-modal
      :showModal="showSearchModal"
      :searchQuery.sync="searchQuery"
      @close="showSearchModal = false"
    />
  </div>
</template>
<script>
import SearchModal from "./SearchModal.vue";
export default {
  name: "AppBar",
  components: {
    SearchModal,
  },
  data: function () {
    const lang = localStorage.getItem("lang") || "ar";

    return {
      showSearchModal: false,
      searchQuery: "",
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      selectedLanguage: lang,
      isActive: false,
      drawer: false,
      on: false,
      tab: null,
      menus: [],
      menus_2: [],
      items: [
        {
          title: "Main dashboard",
          icon: "mdi-home",
          route: "",
        },
      ],
      socialButtons: [
        {
          icon: "mdi-facebook",
          href: "https://www.facebook.com/governmentwebsit/?locale=ar_AR",
        },
        // Add more social buttons as needed
      ],
    };
  },
  computed: {},
  created() {
    //assign menus from json
    this.menus = this.json.menus;
    this.menus_2 = this.json.menus_2;
  },
  methods: {
    switchLanguage(language) {
      this.selectedLanguage = language;
      localStorage.setItem("lang", language);
      location.reload();
    },
    makeListAppear(menu) {
      // Set the isActive property to true to show the menu
      this.$set(menu, "isActive", true);
      // Set showChildren property to true to show the children
      this.$set(menu, "showChildren", true);

      // Set isActive property to false for all other menus
      this.menus.forEach((m) => {
        if (m !== menu) {
          this.$set(m, "isActive", false);
          this.$set(m, "showChildren", false);
        }
      });
    },

    makeListDisappear(menu) {
      // Set isActive property to false on mouse leave
      this.$set(menu, "isActive", false);
      this.$set(menu, "showChildren", false);
    },
  },
};
</script>

<style scoped></style>
