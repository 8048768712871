<template>
  <div class="page-container" v-if="articleDetails">
    <v-container fluid class="py-3 pb-10">
      <v-row align-content="stretch">
        <v-col cols="12" md="12" lg="12">
          <v-row align="center">
            <v-col cols="12" md="auto" class="d-flex align-center">
              <img
                src="../assets/PlatformLogo-01.svg"
                class="my-auto"
                width="100"
                height="auto"
              />
              <span
                v-if="
                  language == 'en' &&
                  articleDetails.category_name_english != null
                "
                class="white--text px-4 dark-panel-1 py-1 fa-12"
                >{{ articleDetails.category_name_english }}</span
              >

              <span v-else class="white--text px-4 dark-panel-1 py-1 fa-12">{{
                articleDetails.category_name_arabic
              }}</span>
            </v-col>
          </v-row>
          <v-card elevation="0" class="main-card px-0">
            <v-row>
              <v-col
                v-for="(image, index) in articleDetails.images"
                :key="index"
                cols="12"
                md="6"
              >
                <div class="image-container">
                  <v-img
                    :src="
                      articleDetails.images && articleDetails.images.length > 0
                        ? image.image_path
                        : require('@/assets/No-Image-Placeholder.png')
                    "
                    class="mt-2 width-full"
                    ><div class="gradient-free">
                      <v-card-title class="pb-0 pt-2 px-2">
                        <span
                          v-if="image.type == 'original'"
                          class="approved-tag white--text px-4 py-2 fa-18 d-block"
                        >
                          <v-icon dark class="white--text"
                            >mdi-check-circle-outline</v-icon
                          >
                          {{ json.postStatus.approved }}</span
                        >
                        <span
                          v-else
                          class="fake-tag white--text px-5 py-2 fa-18 d-block"
                        >
                          <v-icon dark class="white--text"
                            >mdi-hand-back-left</v-icon
                          >
                          {{ json.postStatus.fake }}</span
                        >
                      </v-card-title>
                    </div></v-img
                  >
                </div>
              </v-col>
            </v-row>
            <v-card-title class="px-0">
              <v-card-title
                v-if="language == 'en' && articleDetails.title_english != null"
                class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                >{{ articleDetails.title_english }}</v-card-title
              >

              <v-card-title
                v-else
                class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                >{{ articleDetails.title_arabic }}</v-card-title
              >
            </v-card-title>

            <v-row class="pb-1 mt-2">
              <v-col class="align-self-end fa-10 py-0" cols="12">
                <v-icon class="white--dark mx-2"
                  >mdi-calendar-account-outline</v-icon
                >
                <span class="white--dark">{{
                  formatDate(articleDetails.created_at)
                }}</span>
              </v-col>
              <v-col class="align-self-end fa-12 py-0" cols="12">
                <v-icon class="white--dark mx-2">mdi-eye-outline</v-icon>
                <span class="white--dark">{{
                  articleDetails.views_count
                }}</span>
              </v-col>
              <v-col cols="12">
                <v-col cols="12" class="mb-3">
                  <div
                    v-if="
                      language == 'en' && articleDetails.content_english != null
                    "
                    class="white pa-3"
                  >
                    <span class="px-4 py-1 fa-14 lg:fa-20 justified">{{
                      articleDetails.content_english
                    }}</span>
                  </div>
                  <div v-else class="white pa-3">
                    <span class="px-4 py-1 fa-14 lg:fa-20 justified">{{
                      articleDetails.content_arabic
                    }}</span>
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" v-if="allRelatedArticles != ''">
          <v-col cols="12 pb-8 px-0">
            <span class="fa-20 mb-3">
              {{ json.articleDetails.relatedArticles }}
            </span>
          </v-col>

          <v-row>
            <v-col
              v-for="(item, index) in allRelatedArticles
                .filter((related) => related.id !== postId)
                .slice(0, 6)"
              :key="index"
              cols="12"
              md="4"
              lg="4"
              class="mb-0 px-2"
            >
              <v-row>
                <v-col cols="4" class="pt-0">
                  <v-img
                    @click="postDetails(item)"
                    :src="
                      item.images && item.images.length > 0
                        ? item.images[0].image_path
                        : require('@/assets/No-Image-Placeholder.png')
                    "
                    class="mx-auto same-length main-item-image clickable"
                    width="100%"
                    height="auto"
                  >
                    <div class="gradient-overlay"></div>
                  </v-img>
                </v-col>
                <v-col cols="8" class="pt-0 my-0">
                  <span
                    v-if="language == 'en'"
                    class="white--text px-4 dark-panel-2 py-1 fa-12"
                    >{{ item.category_name_english }}</span
                  >

                  <span
                    v-else
                    class="white--text px-4 dark-panel-2 py-1 fa-12"
                    >{{ item.category_name_arabic }}</span
                  >
                  <v-card-title
                    v-if="language == 'en' && item.title_english != ''"
                    class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                    >{{ item.title_english }}</v-card-title
                  >
                  <v-card-title
                    v-else
                    class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                    >{{ item.title_arabic }}</v-card-title
                  >

                  <v-card-text class="align-self-end fa-10 py-0 px-0" cols="12">
                    <v-icon class="white--dark" small
                      >mdi-calendar-account-outline</v-icon
                    >
                    <span class="white--dark fa-10 mx-2">{{
                      formatDate(item.created_at)
                    }}</span>
                  </v-card-text>
                  <v-card-text class="align-self-end fa-12 py-0 px-0" cols="12">
                    <v-icon class="white--dark" small>mdi-eye-outline</v-icon>
                    <span class="white--dark mx-2">{{ item.views_count }}</span>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="loading" dir="ltr">
      <v-progress-circular
        class="mx-2"
        indeterminate
        color="light"
      ></v-progress-circular>
      <span class="fa-24"> Loading data ...</span>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    const lang = localStorage.getItem("lang") || "ar";

    return {
      loading: false, // Add loading state
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      language: lang,

      info: {
        page: 1,
        category: null,
      },

      postId: 0,
    };
  },

  computed: {
    ...mapGetters([
      "articleDetails",
      "allRelatedArticles",
      "getRole",
      "getUser",
    ]),
  },

  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.postId = newVal;
        this.fetchArticleById(this.postId)
          .then(() => {
            this.disablePagination = false;
          })
          .catch(() => {
            this.disablePagination = false;
          });
      }
    },
  },

  beforeRouteUpdate(to, from, next) {
    this.loading = true; // Show loading screen
    // Fetch article details when route parameter 'id' changes
    this.postId = to.params.id;
    this.fetchArticleById(this.postId)
      .then(() => {
        this.info.category = this.articleDetails.category_id;
        this.fetchRelatedCategories(this.info);
        setTimeout(() => {
          this.loading = false; // Hide loading screen
          next();
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        }, 1000);
      })
      .catch((error) => {
        console.error(
          "An error occurred while fetching article details:",
          error
        );
        this.loading = false; // Hide loading screen
        next();
      });
  },
  created() {
    this.postId = this.$route.params.id;
    this.loading = true;
    this.fetchArticleById(this.postId)
      .then(() => {
        this.info.category = this.articleDetails.category_id;
        this.fetchRelatedCategories(this.info);
        setTimeout(() => {
          this.loading = false;
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        }, 1000);
      })
      .catch((error) => {
        console.error(
          "An error occurred while fetching article details:",
          error
        );
        this.loading = false;
        9;
      });
  },

  methods: {
    ...mapActions(["fetchArticleById", "fetchRelatedCategories"]),

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },

    postDetails(item) {
      this.$router.push({ name: "PostDetails", params: { id: item.id } });
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.main-card {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

.main-card .main-item-image {
  width: 100%;
  height: auto;
  display: block;
  transition: filter 0.3s;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 100, 0.8),
    rgba(0, 0, 100, 0.4)
  ) !important;
}

.main-card .main-item-image:hover {
  filter: brightness(1.2);
}

.gradient-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 200, 0.2),
    rgba(0, 0, 200, 0.2)
  );
  z-index: 1;
  pointer-events: none; /* Ensure the overlay doesn't block interactions with the image */
}

.gradient-overlay-2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 120, 0.2)
  );
  z-index: 1;
  pointer-events: none; /* Ensure the overlay doesn't block interactions with the image */
}

.blog-card {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
}

.blog-card .main-item-image {
  width: 70 !important;
  height: auto;
  display: block;
  transition: filter 0.4s !important;
}

.blog-card:hover .main-item-image {
  filter: brightness(1.2);
}

.white--text {
  color: white !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block; /* Necessary to apply text-overflow in flex container */
}

.text-truncate-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
