<template>
  <div class="white">
    <div class="page-container">
      <v-container fluid class="py-5">
        <v-row align="center">
          <v-col cols="12" md="auto" class="d-flex align-center">
            <img
              src="../../assets/PlatformLogo-01.svg"
              class="my-auto"
              width="100"
              height="auto"
            />
            <span class="fa-20 ml-2"> {{ $t("section6.title2") }} </span>
          </v-col>
        </v-row>

        <v-row align-content="stretch">
          <v-col
            v-for="(institute, index) in allInstitutes"
            :key="index"
            cols="6"
            md="2"
            class="text-center d-flex align-center"
          >
            <div class="institute-card text-center mx-auto">
              <!-- Logo (assuming institute.logo is the path to the image) -->
              <a
                :href="formatLink(institute.link)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="circular-image-container mx-auto">
                  <!-- Logo -->
                  <img :src="institute.logo" class="logo" />
                </div>

                <!-- Name of the institute -->
              </a>

              <div
                v-if="language == 'en'"
                class="institute-name small-text text-center mt-2"
              >
                {{ institute.name_english }}
              </div>

              <div v-else class="institute-name small-text text-center mt-2">
                {{ institute.name_arabic }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    const lang = localStorage.getItem("lang") || "ar";
    return {
      institutesNet: [],
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      language: lang,
    };
  },
  computed: {
    ...mapGetters(["allInstitutes"]),
  },

  methods: {
    ...mapActions(["fetchInstitutes"]),
    formatLink(link) {
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        return `https://${link}`;
      }
      return link;
    },
  },
  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.loading = true;
        this.fetchInstitutes(this.info)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
  created() {
    this.loading = true;
    this.fetchInstitutes(this.info)
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  mounted() {
    // Load JSON data using an HTTP request or import statement
    if (this.language == "en") {
      import("../../assets/data/organizations_eng.json")
        .then((response) => response.default)
        .then((data) => {
          // Assuming the JSON file contains an array of institutes
          this.institutesNet = data;
        })
        .catch((error) => {
          console.error("Error loading JSON file:", error);
        });
    } else {
      import("../../assets/data/organizations.json")
        .then((response) => response.default)
        .then((data) => {
          // Assuming the JSON file contains an array of institutes
          this.institutesNet = data;
        })
        .catch((error) => {
          console.error("Error loading JSON file:", error);
        });
    }
  },
};
</script>
<style scoped></style>
